/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from 'react';
import GlobalStateProvider from './src/context/GlobalStateProvider';
// Tailwind [https://tailwindcss.com/docs/guides/gatsby]
import "./src/tailwind/global.css"
// import "./src/styles/global.css"
// import "./src/styles/tmp-pw.css"

export const wrapRootElement = ({ element }) => (
  <GlobalStateProvider>{element}</GlobalStateProvider>
);

// 
// 簡易パスワードロック
//
let isFirstLoad = true
let isAuthenticated = false

const createModal = () => {
  const modal = document.createElement("div")
  modal.setAttribute("id", "password-modal")
  modal.innerHTML = `
  <div class="modal-content">
    <h2>パスワードを入力してください</h2>
    <form id="password-form">
      <input type="text" id="password-input" />
      <button id="submit-password">送信</button>
    </form>
    <p id="error-message" class="hidden">パスワードが違います。もう一度試してください。</p>
  </div>
  `
  document.body.appendChild(modal)
}

const showModal = () => {
  const modal = document.getElementById("password-modal")
  modal.style.display = "block"
}

const hideModal = () => {
  const modal = document.getElementById("password-modal")
  modal.style.display = "none"
}

const checkPassword = (event) => {
  event.preventDefault() // ページリロードを防ぐ
  const password = "零"
  const inputPassword = document.getElementById("password-input").value

  if (inputPassword === password) {
    isAuthenticated = true
    hideModal()
  } else {
    const errorMessage = document.getElementById("error-message")
    errorMessage.classList.remove("hidden")
  }
}

// export const onInitialClientRender = () => {
//   if (isFirstLoad) {
//     createModal()
//     showModal()
//     document.getElementById("submit-password").addEventListener("click", checkPassword)

//     isFirstLoad = false
//   }
// }