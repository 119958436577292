import React, { useReducer } from 'react';
import GlobalStateContext from './GlobalStateContext';

// GlobalStateReducerを定義
const GlobalStateReducer = (state, action) => {
    switch (action.type) {
      case "SET_IS_BOOK_DETAIL_PAGE_ON":
        return {
          ...state,
          isBookDetail: true,
        };
  
      case "SET_IS_BOOK_DETAIL_PAGE_OFF":
        return {
          ...state,
          isBookDetail: false,
        };
  
        // HomeのuseEffetctのマウント時に利用している
        case "SET_IS_HOME_PAGE_ON":
          return {
            ...state,
            isHome: true,
          };
          
        // HomeのuseEffetctのアンマウント時に利用している
        case "SET_IS_HOME_PAGE_OFF":
          return {
            ...state,
            isHome: false,
          };
    
      case "UPDATE_CLICKED_BTN_LEFT":
        return {
          ...state,
          clickedBtnLeft: action.payload,
        };
  
      case "UPDATE_CLICKED_BTN_RIGHT":
        return {
          ...state,
          clickedBtnRight: action.payload,
        };
  
      default:
        return state;
    }
  };  

const GlobalStateProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(GlobalStateReducer, {
    clickedBtnLeft: null,
    clickedBtnRight: null,
    isBookDetail: false,
    isHome: false,
    // ここにグローバルステートの初期値を設定
  });

  return (
    <GlobalStateContext.Provider value={{ globalState, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
